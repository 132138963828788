
// ==> Client Config 372 <== //
@import './Config/Salish/clientStyleConfig.scss';
// ==================== //
// $max-content-width: 200px;

// @font-face {
//     font-family: 'Arial';
//     src: url(/public/fonts/Arial.ttf);
// }
// @font-face {
//     font-family: 'Helvetica';
//     src: url(/public/fonts/Helvetica.ttc);
// }
// @font-face {
//     font-family: 'HelveticaNeue';
//     src: url(/public/fonts/HelveticaNeue.ttc);
// }

@font-face {
  font-family: 'Aboriginal Sans';
  src: url('/public/Fonts/AboriginalSansREGULAR.ttf');
  src: url('/Fonts/AboriginalSansREGULAR.ttf');
  font-weight: normal;
  font-style: normal;
}

.nativeLangFont {
  font-family:'Aboriginal Sans';
}
