/* Salish */
$footerImage: url(/images/footer_salish.png);


$headerColor: rgba(159,114,63,1);
$spacer1Color: rgba(122,131,44,1);
$spacer2Color: rgba(208,164,117,1);
$spacer3Color: rgba(152,162,69,1);
$spacer4Color: rgba(212,184,145,1);

$menuColor: rgba(159,114,63,1);
$activeTabColor: rgba(212,184,145,1);

$htmlColor: rgba(0,0,0,1);
$AppColor: rgba(153,153,153,0.3);

.clientConfig-footerStyle {
  background-image: url(/images/footer_salish.png);
  background-size: contain;
  background-position: center;
};
